<template>
  <div class="">
    <div class="h-48 fill_all fixed bg-gray2 opacity-70 transition filter blur" v-if="!ShowErr"></div>
    <div class="flex justify-center relative z-50 pt-14" v-if="!ShowErr">
      <div class="card bg-white rounded-xl p-4 m-4 mt-0 pt-0 sm:w-auto lg:w-11/12 xl:w-10/12 2xl:w-8/12">
        <div class="relative">
          <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-8 w-full heading-2">
            Payment
          </div>
        </div>
        <div class="mt-12">
          <div class="" v-if="paymentDetail !== null">
              <div class="">
                <div class="  mb-4 grid grid-cols-12">
                  <div class="col-span-6">
                    <p class="text-primary heading-2 font-bold">{{OrgDetail.orgName}}</p>
                    <p class="text-text2">
                      {{OrgDetail.addressLine1 !== '' ? OrgDetail.addressLine1 +',' : ''}} {{OrgDetail.addressLine2 !== '' ? OrgDetail.addressLine2 + ',' : ''}}
                    </p>
                    <p class="text-text2">
                      {{OrgDetail.city !== '' ? OrgDetail.city + ',' : ''}} {{OrgDetail.state}} 
                    </p>
                    <p v-if="OrgDetail.country !== ''" class="text-text2">
                      {{OrgDetail.country !== '' ? OrgDetail.country + ',' : ''}} {{ OrgDetail.zip }}
                    </p>
                  </div>
                  <div class="col-span-6 text-right">
                    <Button class="mb-1" :isSqure="true" :btnSize="'small'" style="letter-spacing: 1.4px;" :textColor="'white'" :btnColor="'primary'" :btnText="'DOWNLOAD'" @buttonAction="downloadPayment()"/>
                  </div>
                </div>
                <div>
                  <div class="divider"></div>
                    <div class=" text-gray3 my-2 items-center">
                      <div class="">
                        <div class="heading-4 text-gray4">Payment Receipt:</div>
                        <p class="text-text2 heading-2 font-bold mt-1">{{paymentDetail.companyName}}</p>
                        <div class="">
                          <span class="flex items-center">
                            <p class="w-36">Payment Date:</p>
                            <p class="text-gray4 pl-2">{{paymentDetail.addedDate | dateFilterInvoiceRegularFormate}}</p>
                          </span>
                          <span class="flex items-center">
                            <p class="w-36">Payment Mode:</p>
                            <p class="text-gray4 pl-2">{{paymentDetail.paymentMethodName}}</p>
                          </span>
                          <span class="flex items-center">
                            <p class="w-36">Transaction ID:</p>
                            <p class="text-gray4 pl-2">{{paymentDetail.transactionId}}</p>
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
                <div>
                  <div class="divider mb-3"></div>
                  <div class="grid grid-cols-12">
                    <div class="box col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-6 md:col-span-6">
                      <div class="bg-success rounded p-2 card" style="box-shadow: rgb(44 148 39) -2px 3px 4px 0px;">
                        <div>
                          <div class="heading-2 text-white text-center font-semibold ">Total Amount</div>
                          <div class="heading-3 text-white text-center font-semibold">
                            {{parseInt(paymentDetail.paymentAmount) | amountFormaterWithToFix}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="paymentDetail.paymentNotes !== ''">
                  <div class="divider mt-4"></div>
                  <div class="pt-4">
                    <p class="text-gray4 heading-4">Payment Notes:</p>
                    <p class=" text-text2">{{paymentDetail.paymentNotes}}</p>
                  </div>
                </div>
                <div>
                  <div class="divider mb-2 mt-4"></div>
                  <div class="heading-4 text-gray4 mb-2">Payment For:</div>
                  <div class="" v-if="screenWidth >= 830">
                    <div class="bg-primary text-white flex table_containder card rounded p-2">
                      <div class="hash_col  font-bold heading-4 ">Invoice Number</div>
                      <div class="desc_col font-bold heading-4 ">Invoice Date</div>
                      <div class="amount_col font-bold heading-4 text-right">Payment</div>
                    </div>
                    <div class=" flex table_containder" style="flex-flow:wrap;">
                      <div class="flex p-2  rounded w-full my-1 card hover:bg-gray-100 cursor-pointer" @click="redirectToDetail(name)" v-for="(name, index) in paymentDetail.linkedInvoiceList" :key="index">
                        <div class="hash_col text-text2 font-semibold">
                          #{{name.invoiceNumber}}
                        </div>
                        <div class="desc_col text-gray4 heading-6">
                          <span v-if="name.invoiceDate !== ''">{{ name.invoiceDate | dateFilterInvoiceRegularFormate}}</span>
                          <span v-else>-</span>
                        </div><div class="amount_col text-gray4 heading-6 text-right">
                          <span v-if="name.grandTotal !== ''">{{ name.grandTotal |amountFormaterWithToFix}}</span>
                          <span v-else>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" v-if="screenWidth < 830">
                    <div style="overflow-y: auto; white-space: nowrap;" class="">
                      <div v-for="(name, index) in paymentDetail.linkedInvoiceList" :key="index"  class="card m-2 rounded heading-6 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="redirectToDetail(name)">
                        <div class="flex my-1 justify-between" >
                          <div class="heading-5 font-semibold text-gray4">
                            #<span>{{name.invoiceNumber}}</span>
                          </div>
                          <div class="heading-5 font-semibold text-gray4">
                            <div>{{name.recordedAmount | amountFormaterWithToFix}}</div>
                          </div>
                        </div>
                        <div class="">
                          <span class="flex items-center">
                            <p class="text-gray3 w-20">Inv Total:</p>
                            <p class="text-gray4 pl-2">{{name.grandTotal | amountFormaterWithToFix}}</p>
                          </span>
                          <span class="flex items-center">
                            <p class="text-gray3 w-20">Inv Dt:</p>
                            <p class="text-gray4 pl-2">{{name.invoiceDate | dateFilterInvoiceRegularFormate}}</p>
                          </span>
                          <div class="flex items-center justify-between">
                            <span class="flex items-center">
                              <p class="text-gray3 w-20">Due Dt:</p>
                              <p class="text-gray4 pl-2">{{name.dueDate | dateFilterInvoiceRegularFormate}}</p>
                            </span>
                            <span>
                              <p :style="'color:' + name.invoiceStatusColor" class="text-text2 heading-5 pl-2">{{name.invoiceStatusName}}</p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-12 ">
                    <div class=" box lg:col-start-5 2xl:col-start-7 xl:col-start-7  lg:col-span-8  2xl:col-span-6 xl:col-span-6 col-span-12 flex justify-end items-baseline mt-1">
                      <p class="text-right text-text2 font-semibold"> Total of payment linked with invoice:</p>
                      <p class="detail_col text-right text-text2 font-semibold mr-2">{{parseInt(paymentDetail.paymentAmount) | amountFormaterWithToFix}}</p>
                    </div>
                  </div>
                  <div class="grid grid-cols-12">
                    <div class=" box lg:col-start-5 2xl:col-start-7 xl:col-start-7  lg:col-span-8  2xl:col-span-6 xl:col-span-6 col-span-12 flex justify-end items-baseline mt-1">
                      <p class="text-right text-text2 font-semibold">Total Payment:</p>
                      <p class="detail_col text-right text-text2 font-semibold mr-2">{{parseInt(paymentDetail.paymentAmount) | amountFormaterWithToFix}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div  v-if="ShowErr && firstLoading">
      <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
        <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
            <p class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-gray-300"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
            <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Please Check Your Payment  Link and Try Again Later</p>
        </div>
      </div>
     </div>
   </div>
</template>
<script>
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
export default {
  components: {
    Button
  },
  data() {
    return {
      paymentDetail: {},
      OrgDetail: {},
      ShowErr: true,
      firstLoading: false,
      screenWidth: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.myEventHandler()
    this.getInvoiceDetail()
  },
  methods: {
    getInvoiceDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.HashPaymentDetail(
        this.$route.params.hashId,
        response => {
          this.ShowErr = false
          this.firstLoading = true
          this.paymentDetail = response.Data !== null ? response.Data : {}
          document.title = 'Payment: ' + this.paymentDetail.companyName
          this.OrgDetail = response.Data.organizationDetail !== null ? response.Data.organizationDetail : {}
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          if (error.message === 'No Record Found') {
            this.ShowErr = true
            this.firstLoading = true
          }
          this.ShowErr = true
          this.firstLoading = true
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    redirectToDetail (data) {
      let routeData1 = this.$router.resolve({name: 'InvoiceDetailHash', params: {hashId: data.linkHash}})
      window.open(routeData1.href, '_blank')
    },
    downloadPayment () {
      this.$router.push({name: 'DownloadHashPayment', params: {hashId: this.$route.params.hashId}})
    },
    myEventHandler () {
      this.screenWidth = window.innerWidth
    }
  },
};
</script>
<style scoped>
.hash_col {
    min-width: 20px !important;
    width: 489px !important;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    padding-right: 8px;
  }
  .desc_col {
    min-width: 20px !important;
    width: 329px !important;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    padding-right: 8px;
  }
  .amount_col {
    min-width: 20px !important;
    width: 400px !important;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    padding-right: 8px;
  }
  .detail_col {
    min-width: 20px !important;
    width: 158px !important;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    padding-right: 8px;
  }
</style>